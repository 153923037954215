import React from 'react';
import type { FC } from 'react';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { RadiationTherapyPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';

interface PageProps {
  data: RadiationTherapyPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="放射線治療コンサルテーション"
      description="放射線取扱主任者第1種の資格を有した獣医師が各腫瘍に適した放射線治療をご案内、ご説明いたします。2023年春、常電圧放射線発生装置を導入予定"
      keywords="放射線治療コンサルテーション"
    />
    <article id="clinical-examination">
      <h2>放射線治療コンサルテーション - Radiation therapy -</h2>
      <div className="skew-border"></div>
      <section>
        <p>
          近年、日本の動物医療においても放射線治療が普及してきており、特に東京近郊では放射線施設が充実してきております。
        </p>
        <p>
          私は放射線管理の国家資格である「放射線取扱主任者第1種」の免状を有しており、放射線治療における適応・効果・副作用などの専門的な情報をご提供できます。
        </p>
        <p>
          これにより放射線装置を備えた近郊の大学病院や医療センターにスムーズな紹介が可能です。
        </p>
      </section>
    </article>
  </Layout>
);
export default Page;

export const query = graphql`
  query RadiationTherapyPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
